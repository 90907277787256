
.react-datepicker__input-container{
    border:2px solid #dce0e4;
    border-radius: 10px;
}

.react-datepicker-wrapper input{
    height: 50px;
    margin-top: 0px;
    border-radius: 9px;
    width: -webkit-fill-available;
    padding: 5px;
    border-color:  #e5e8eb;
    border: 2px #dce0e4
}
.react-flow__node{
    width:"100px"
}
.high {
    background-color: #ff48426f ;
}
 
.medium {
    background-color: #e9bc356e ;
}
.low {
    background-color: #54d62c29 ;
}
.MuiTableCell-head {
    box-shadow: none !important;
}
.MuiTableCell-head {
    box-shadow: none !important;
}
.custom-antd-input input{
    height: 55px;
}
#general-orders-date-filter input{
    width:300px
}
.MuiLink-root {
    cursor: pointer;
}

.css-dev-only-do-not-override-17sses9.ant-picker-focused.ant-picker, .ant-picker-focused.ant-picker, .ant-picker:hover, .ant-picker:active, .ant-picker:target, .ant-picker:focus{
   border-color: black;
}
.ant-picker .ant-picker-input >input {
    color: #1b1c1c;
    font-family: 'Public Sans';
}
.css-dev-only-do-not-override-17sses9.ant-picker, .ant-picker{
    border-radius: 7px;
    border: 1px solid #dce0e4;
}
.css-dev-only-do-not-override-17sses9.ant-picker .ant-picker-input>input::placeholder, .ant-picker .ant-picker-input>input::placeholder{
  color: #96a2af;
}
.react-pdf__Page__canvas{
    width: 520px !important;
}  

.MuiAvatar-img{
    object-fit: unset !important;
}
